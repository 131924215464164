document.addEventListener('DOMContentLoaded', function () {
    // Add the custom styles to the document
    var style = document.createElement('style')
    style.innerHTML = `
        .note_row th {
            display: flex;
            align-items: center;
        }

        .note-row td {
            width: 50%;
        }
    `
    document.head.appendChild(style)

    // Find all table rows
    var rows = document.querySelectorAll('tr')

    // Iterate over the rows to find the one containing the "Note:"
    var noteRow = null
    rows.forEach(function (row) {
        var th = row.querySelector('th')
        if (th && th.textContent.trim() === 'Note:') {
            noteRow = row
        }
    })

    if (noteRow) {
        // Add a class to the <tr> containing the note
        noteRow.classList.add('note-row')

        var th = noteRow.querySelector('th')
        var noteCell = noteRow.querySelector('td')
        noteCell.setAttribute('id', 'editable-note')

        // Create an Edit/Save button and append it to the th element
        var editSaveButton = document.createElement('button')
        editSaveButton.textContent = 'Edit'
        editSaveButton.setAttribute('id', 'edit-save-note-btn')
        editSaveButton.classList.add('wp-block-button__link')
        editSaveButton.style.marginInline = '10px'
        editSaveButton.style.cursor = 'pointer'
        th.appendChild(editSaveButton)

        // Add event listener to the button
        editSaveButton.addEventListener('click', function () {
            if (editSaveButton.textContent === 'Edit') {
                // Switch to editing mode
                noteCell.setAttribute('contenteditable', 'true')
                noteCell.focus()
                editSaveButton.textContent = 'Save'
            } else if (editSaveButton.textContent === 'Save') {
                // Clone the spinner template and append it next to the Save button
                var spinnerTemplate = document.getElementById('spinner-template')
                var spinnerClone = spinnerTemplate.content.cloneNode(true)
                editSaveButton.parentNode.insertBefore(spinnerClone, editSaveButton.nextSibling)

                // Save the note and switch back to view mode
                var updatedNote = noteCell.textContent

                // Disable editing
                noteCell.setAttribute('contenteditable', 'false')

                // Send the updated note to the server via AJAX using the orderId from the HTML
                var xhr = new XMLHttpRequest()
                xhr.open('POST', wc_add_to_cart_params.ajax_url, true)
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
                xhr.onload = function () {
                    // Remove the spinner after the request is finished
                    var spinner = document.getElementById('order-fields-spinner')
                    if (spinner) {
                        spinner.remove()
                    }

                    if (xhr.status === 200) {
                        editSaveButton.textContent = 'Edit' // Switch back to Edit mode
                    }
                }
                xhr.send('action=save_order_note&order_id=' + encodeURIComponent(orderId) + '&note=' + encodeURIComponent(updatedNote))
            }
        })
    }
})
